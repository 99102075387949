@import "black-tie/_variables";
@import "black-tie/_mixins";
@import "black-tie/_icons";

@include establish-baseline;

html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}

body {
  background: $clr_bg1;
  font-family: $font-a;
  //font-weight: 200;
  color: $clr_text1;
	-webkit-font-smoothing: antialiased;
	margin: 0;
	padding: 0;
	::-moz-selection { /* Code for Firefox */
    background: $clr_bg3;
  }
  ::selection {
      background: $clr_bg3;
  }
}

/* 
  Typography ------------------------------------------------------------------
*/

h1, h2, h3, h4 {
  @include heading();
}

h1 {
  @include h1(0.5, 0);
}
h2 { 
  @include h2(0.5, 0);
}
h3 { 
  @include h3(0.5, 0);
}
h4 { 
  @include adjust-size(1, 1, 0.5, 0);
}
h5 { 
  @include adjust-size(1, 1, 0.5, 0);
}
h6 { 
  @include adjust-size(1, 1, 0.5, 0);
}

p {
  margin: 0;
  padding-bottom: rhythm(0.5);
  &:empty {
    display: none;
  }
  &.p-alt-1 {
    font-family: $font-b;
    font-weight: 100;
  }
  &.p-alt-2 {
    font-family: $font-b;
    font-weight: 100;
    @include adjust-size(1.2, 1);
  }
  &.large { 
    @include adjust-size(1.2, 1); 
  }
  &.larger { 
    @include adjust-size(1.5, 1); 
  }
}

blockquote { 
  position: relative; 
  padding: 0 0 0 rhythm();
  margin: 0;
  p:not(:last-child) {
    padding-bottom: 0; 
  }
}

small, .note {
  @include subtext;
}

strong, b {
  font-weight: normal;
}

i, em {
  
}

sup, sub {
  line-height: 1;
}

.meta {
  @include subtext;
}

.taxonomy {
  @include subtext;
}

.entry-content {
  line-height: $baseline;
}


.footnotes {
  margin-top: rhythm(12);
  > hr {
    display: none;
  }
  ol {
    @include small();
  }
  
}

hr {
  height: 1px;
  border: 0;
  border-style: none;
  background: $clr_border;
}
/* 
  Lists  ------------------------------------------------------------------
*/

ul, ol {
  margin: 0;
  @include adjust-size(1, 1, 0.5, 0 );
  &.ruled {
    border-bottom: $border2;
    list-style: none;
    padding-left: 0;
    li {
      width: 100%;
      clear: both;
      border-top: $border2;
      padding: rhythm(0.5) 0;
    }
  }
  &.nav {
    padding-left: 0;
    list-style: none;
  }
}

ol { 
  list-style: decimal;
  padding: 0 0 0 1em; 
}

ul ul, ol ul { 
   list-style-type: circle; 
   list-style-position: outside; 
   margin-left: 15px; 
}

ol ol, ul ol { 
   list-style-type: lower-latin; 
   list-style-position: outside; 
   margin-left: 15px; 
}

ul.block_list {
  border: $border;
  @include border-radius($radius);
  margin-bottom: rhythm();
  width: 100%;
  float: left;
  li {
    @include pad-element();
    border-bottom: $border;
    width: 100%;
    float: left;
    &:last-child {
      border-bottom: 0;
    }
  }
}
ul, ol {
  &.lined {
    li {
      padding: rhythm(0.5) 0;
      border-top: $border2;
      &:first-child {
      }
    }
  }
}


/* 
  Messages  ------------------------------------------------------------------
*/
.message, .error, .alert {
  @include pad-element();
  @include border-radius(4px);
  margin-bottom: rhythm(0.5);
  width: 100%;
  padding-right: 2em;
  position: relative;
  &:after {
    @include bticon($bt-var-times);
    @include adjust-size(0.5, 1, 0, 0);
    position: absolute;
    top: 0;
    right: 10px;
  }
}

ul {
  &.message, &.error, &.alert {
    color: $clr_text2;
    @include border-radius(0);
    padding: 0;
    background-color: transparent !important;
    li {
      @include pad-element();
      @include border-radius(4px);
      width: 100%;
      margin-bottom: rhythm(0.25);
      &:before {
        margin-right: 5px;
      }
    }
  }
}

.message {
  color: $clr_text2;
  background: lighten($clr_text2, 40);
}

.error {
  color: $clr_error;
  background: lighten($clr_error, 50);
  li {
    background: lighten($clr_error, 50);
    &:before {
      @include bticon($bt-var-exclamation-circle);
    }
  }
}
.warning {
  color: $clr_warning;
  background: lighten($clr_warning, 50);
  li {
    background: lighten($clr_warning, 50);
    &:before {
      @include bticon($bt-var-exclamation-circle);
    }
  }
}
.success {
  color: $clr_success;
  background: lighten($clr_success, 50);
  li {
    background: lighten($clr_success, 50);
    &:before {
      @include bticon($bt-var-check-circle);
    }
  }
}
/* 
  Links  ------------------------------------------------------------------
*/
a { 
  text-decoration: none; 
  color: $clr_link;
  @include font-b();
  &:hover {
    color: $clr_hover; border-color: $clr_hover;
    h1, h2, h3, h4, h5 {
      color: $clr_hover;
      border-color: $clr_hover;
    }
  }
  &.disabled {
    cursor: default;
    opacity: 0.5;
    &:hover {
      color: inherit;
    }
  }
}


h1, h2, h3 {
  a { border-bottom: none; }
}

.entry-content {
  a { 
    @include underline;
    border-color: lighten($clr_link, 30);
    &:hover {
      border-color: $clr_hover;
    }
  }
  table a {
    //border-bottom: 0;
  }
}

a.read-more {
  //@extend .button;
}

.close, .remove {
  visibility: hidden;
  line-height: 0;
  &:after {
    @include bticon($bt-var-times);
    visibility: visible;
  }
}


/* 
  Images & Videos  ------------------------------------------------------------------
*/
img, svg {
  max-width: 100%; 
  height: auto; 
  width: auto;
  image-rendering:optimizeQuality; 
  -ms-interpolation-mode:bicubic; 
}
figure, img, svg { 
  margin: 0 0 rhythm(1) 0;
  &:first-child {
    margin-top: 0;
  }
}
figure {
  max-width: 100% ; 
  clear: both;
  line-height: 0;
  position: relative;
  > img {
    margin-top: 0;
    margin-bottom: 0;
  }
}

figcaption { 
  //@include adjust-size(0.8, 1, 0, 0);
  //color: $clr_text2;
  line-height: $baseline;
}

figure.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
  margin-bottom: rhythm();
  iframe, object, embed {
    max-width: 100%;
    position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
	}
}

.wp-video, .video {
	> * { max-width: 100%; }
}

/* 
  Galleries  ------------------------------------------------------------------
*/
.gallery {
  clear: both;
  @include nest();
  .gallery-item {
    min-height: 100%;
    vertical-align: middle;
    text-align: center;
    figure {
      img {
      }
    }
    figcaption {
      text-align: left;
    }
  }
  &.v-aligntop {
    blockquote, img {
      vertical-align: top;
    }
  }
  &.v-alignmiddle {
    .gallery-item {
      vertical-align: middle;
    }
  }
  &.v-alignbottom {
    .gallery-item {
      vertical-align: bottom;
    }
  }
  &.asymmetrical, &.quote {
    &.aignleft .gallery-item {
      text-align: left;
    }
    &.aligncenter .gallery-item {
      text-align: center;
    }
    &.alignright .gallery-item {
      text-align: right;
    }
  }
}

/* 
  Tables  ------------------------------------------------------------------
*/

table {
  padding-left: 0;
  padding-right: 0;
  width: 100%;
  margin-bottom: rhythm(1);
  border-collapse: collapse;
  border-spacing: 0;
  thead, th {
    display: none;
    font-weight: normal;
    text-align: left;
    font-style: italic;
  }
  thead {
    td, th {
      border-top: 0;
      padding-top: 0;
    }
  }
  tbody {
  	border-bottom: $border2;
  }
  tr {
    float: left;
    width: 100%;
    padding: rhythm(0.5) 0;
  	border-top: $border2;
    
  }
  td, th {
    display: block;
    float: left;
    width: 100%;
    
    p:last-child {
      padding-bottom: 0;
    }
    &:first-child {
      padding-left:0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
  th {
    @include label();
  }
  img {
    //min-width: rhythm(3);
  }
}

dl {
  dt {
    font-style: italic;
  }
  dd {
  }
}

table.grid {
  width: 100%;
  float: left;
  clear: both;
  display: block;
  tbody {
    width: 100%;
    float: left;
  }
  thead {
    width: 100%;
    float: left;
    tr {
    }
  }
	tr {
    float: left;
    display: block;
	}
  td, th {
    display: block;
    padding: rhythm(0.5) 0;
    @include span(6);
    &:first-child {
      padding-left:0 !important;
    }
    &:last-child {
      padding-right: 0 !important;
    }
  }
}

@include susy-media($break2) {
  table {
    thead {
      display: table-header-group;
      th {
        display: table-cell;
      }
    }
    tr {
      float: none;
      padding: 0;
      border-top: 0;
    }
    td, th {
      padding: rhythm(0.5) gutter();
      display: table-cell;
      float: none;
      width: auto;
    	border-top: $border2;
    }
  }
  .gallery.asymmetrical {  
    .gallery-item:nth-child(2) {
      float: right;
    }
    &.layout-left {
      .gallery-item:first-child {
          figure {
            width: span(8 of 12 no-gutters narrow);
          }
      }
    }
    &.layout-right {
      .gallery-item:nth-child(2) {
        figure {
            width: span(8 of 12 no-gutters narrow);
          }
      }
    }
  }
}