@mixin form-base {
  @include border-radius($radius);
	@include text-shadow(none);
	@include box-sizing(border-box);
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
	color: $clr_text1;
	font-family: $font-b;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-decoration: none; 
  vertical-align: baseline;
  border: $border;
	background-color:transparent;
	height: auto;
	position: relative;
	z-index: inherit;
	&:hover {
		z-index: 100;
		color: $clr_hover;
		border-color: $clr_hover;
	}
	&:focus {
		@include box-shadow(none);
		outline: none;
		z-index: 100;
		color: $clr_hover;
		border-color: $clr_hover;
	}
	&[disabled],
  &.disabled {
    cursor: default;
		color: lighten($clr_text1, 30);
    border-color: lighten($clr_border, 30);
  	&:hover {
  		color: lighten($clr_text1, 30);
      border-color: lighten($clr_border, 30);
  	}
  }
}

form {
	@include placeholder {
		font-family:inherit !important;
		font-weight: inherit !important;
		line-height: inherit !important;
		color: $clr_text1 !important;
	}
}
label {
  @include label();
  @include inline-block;
	img {
    max-height: rhythm(2);
    vertical-align: middle;
    line-height: 0;
    margin: 0 0.5em;
	}
}
h1, h2, h3 {
  label {
    color: $clr_text1;
    padding: 0;
  }
}

fieldset, .fieldset {
  border: none;
  margin: 0 0 rhythm(0.5) 0;
  padding: 0;
  input {
    width: auto;
  }
}
/* 
  Inputs  ------------------------------------------------------------------
*/

textarea,
select,
input,
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
input[type="website"],
input.text {
  @include form-base;
	@include pad-element();
	width: 100%;
	max-width: 100%;
  @include transition(border-color 200ms $easeInOutQuad, color 200ms $easeInOutQuad);
  &:hover {
    @include placeholder {
			color: lighten($clr_hover, 30);
		}
  }
	&:focus {
		@include placeholder {
			//display:none;
			//color:transparent;
		}
	}
	@include placeholder {
		font-family:inherit;
		font-weight: inherit;
		line-height: inherit;
		color: lighten($clr_text1, 30);
	}
}
/*================ Textarea ================*/
	textarea {
		display:block;
	}

/*================ Checkbox & Radio ================*/
	input[type="checkbox"]{
  	&.hidden {
      display:none !important;
    }
  }
input[type="checkbox"],input[type="radio"] {
	width:18px;
	height:18px;
	min-width: 18px;
	min-height: 18px;
	margin: -1px 4px 0 4px;
	box-shadow:0 0 0 0 $clr_text2 inset;
	-webkit-transition:all .2s ease-in-out;
	transition:all .2s ease-in-out;
	position:relative;
	cursor:pointer;
	vertical-align: middle;
	outline:0;
	border-color: $clr_text1;
	padding: 0;
	border-radius:50%;
}

input[type="checkbox"]:after,input[type="radio"]:after {
	content:"";
	display:block;
	position:absolute;
	top:50%;
	left:50%;
	-webkit-transform:scale(0.2);
	transform:scale(0.2);
	-webkit-transition:all .2s ease-in-out .1s;
	transition:all .2s ease-in-out .1s;
	opacity:0;
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity= 0 );
	filter:alpha(opacity=0);
}

input[type="checkbox"]:focus,input[type="radio"]:focus {
	border-color: $clr_text2;
}

input[type="checkbox"]:checked,input[type="radio"]:checked {
	border:none;
	box-shadow:0 0 0 10px $clr_text2 inset;
}

input[type="checkbox"]:checked:after,input[type="radio"]:checked:after {
	-webkit-transform:scale(1);
	transform:scale(1);
	opacity:1;
	-ms-filter:progid:DXImageTransform.Microsoft.Alpha(Opacity= 100 );
	filter:alpha(opacity=100);
}

input[type="checkbox"]:checked:focus,input[type="radio"]:checked:focus {
	border-color:#00c;
}

input[type="radio"] {
	border-radius:50%;
}

input[type="radio"]:after {
	width:4px;
	height:4px;
	margin-left:-2px;
	margin-top:-2px;
	background-color:#fff;
	border-radius:50%;
}

input[type="checkbox"]:after {
	width:10px;
	height:8px;
	margin-left:-5px;
	margin-top:-4px;
	background-image:url(//cdn.shopify.com/s/assets/checkout_v2/checkbox-tick-93a42341be8bddf041ab5d6fe605c48279ff756a7682acb80f3ca8367e9dd6ef.png);
	background-image:url(//cdn.shopify.com/s/assets/checkout_v2/checkbox-tick-66a9a39f70a44a226223ef4c61a701d2d10afaa73ba8846a509fbe58a82f3f36.svg),none;
}

/* 
  Buttons  ------------------------------------------------------------------
*/

input[type=submit], input[type=button], button, a.button, .btn {
  @include form-base;
	cursor: pointer;
	white-space:nowrap;
	@include inline-block;
	@include pad-element();
	background: none;
	&.next:after {
    @include bticon($bt-var-angle-right);
    padding: 0 0 0 0.25em;
	}
	&:hover, &:focus {
	  @include box-shadow(none);
		background:none;
		color: $clr_hover;
		border-color: $clr_hover;
	}
	&:active {
		@include box-shadow(none);
		background:none;
		color: $clr_hover;
		border-color: $clr_hover;
	}
	&.prev:after {
		@include bticon($bt-var-angle-left);
		padding: 0 0.25em 0 0;
	}
  &.icon {
    border: 0;
    padding: 0;
    font-size: inherit;
  }
  &.alt {
  	@include border-radius(1em);
  	border-width: 0;
  	@include box-shadow(-0.125em -0.125em 0.25em 0 $clr_bg1 inset, -0.25em -0.25em 1em 0 lighten($clr_text1, 10) inset, 1px 1px 2px 0 $clr_text1);
  	&:hover, &:focus {
    	@include transform(translate(-1px, -1px));
    	@include box-shadow(-0.125em -0.125em 0.25em 0 $clr_bg1 inset, -0.25em -0.25em 1em 0 lighten($clr_hover, 10) inset, 2px 2px 3px 0 $clr_hover);
  	}
  	&:active {
    	@include transform(translate(0, 0));
    	@include box-shadow(-0.125em -0.125em 0.25em 0 $clr_bg1 inset, -0.25em -0.25em 1em 0 lighten($clr_hover, 10) inset, 1px 1px 2px 0 $clr_hover);
  	}
	}
}

/* 
  Selects  ------------------------------------------------------------------
*/
  select {
  	&:hover {
    	cursor: pointer;
    	color: $clr_hover;
  	}
  	&:focus {
    	@include box-shadow(none);
    	outline: none;
  	}
	}
	.select-wrap {
  	position: relative;
  	select {
    	background-image: none;
  	}
  	&:after {
      @include bticon($bt-var-angle-down);
      position: absolute;
      right: 0;
      @include pad-element();
      @include adjust-size(0.7, 1.5);
    }
    &:hover {
      color: $clr_hover;
    }
	}

.select2-container {
  a {
    background-image: none;
  }
  .select2-choice {
    @extend button;
    background: transparent;
    width: 100%;
    &:hover {
      color: $clr_hover !important;
      border-color: $clr_hover !important;
      a, .select2-default {
        color: lighten($clr_hover, 30) !important;
      }
      .select2-arrow {
        color: $clr_hover !important;
      }
    }
  }
  .select2-default {
    color: lighten($clr_text1, 30) !important;
  }
  .select2-arrow {
    background: transparent !important;
    background-image: none;
    border-width: 0 !important;
    width: 1em !important;
    float: right;
    color: $clr_text1 !important;
    b {
      display: none !important;
    }
    &:after {
      @include bticon($bt-var-angle-down);
      float: right;
      padding-right: 15px;
      @include adjust-size(0.7, 1.5);
    }
  }
}
.select2-dropdown-open {
  @include border-radius($radius $radius 0 0);
  background: $clr_bg1;
  border: 1px solid $clr_hover;
  //margin-right: -1px;
  border-bottom-color: transparent;
	@include transform(translate(0, 0));
  color: $clr_hover;
  .select2-choice {
    @include box-shadow(none);
    border: 0;
  }
  .select2-choice {
    color: lighten($clr_hover, 30) !important;
  }
  .select2-arrow {
    color: $clr_hover !important;
    &:after {
      @include bticon($bt-var-angle-up);
      @include adjust-size(0.7, 1.5);
    }
  }
}
.select2-drop {
  @include border-radius(0 0 $radius $radius);
  border: 1px solid $clr_hover;
  border-top: none;
  color: $clr_hover;
  > div {
    padding: 0;
  }
  input[type=text] {
    @include border-radius(0);
  	padding: rhythm(0.125) 0.5em !important;
    border: 0;
    @include box-shadow(none);
    @include placeholder {
      color: lighten($clr_hover, 30) !important;
    }
  }
}
.select2-results {
  padding: 0;
  li {
    padding: 0 gutter();
  }
  .select2-highlighted {
    background: $clr_hover;
  }
}
/* 
  Classes and templates  ------------------------------------------------------------------
*/

.screen_reader {
	height:0;
	margin:0;
	padding:0;
	visibility: hidden;
}
.quantity, .quantity > input {
  width: 3em;
}

form[role=search] {
  input {
  }
  input[type=search] {
    
  }
  input[type=submit] {
    
  }
}

button.search-toggle {
  @include pad-element(1, 0);
  border: none;
  @include border-radius(0);
  &:hover {
    background-color: transparent;
    color: $clr_hover;
  }
}

#overlay-content {
  form[role=search] {
      @include adjust-size(2,1.5,0,0);
    input {
    }
    input[type=search] {
      
    }
    input[type=submit] {
      
    }
  }
}

/* 
  Mediaqueries  ------------------------------------------------------------------
*/

@media screen and (min-width: $break2) {
    .form-row-first inputinput:not([type=checkbox]):not([type=submit]), input.form-row-first, input.set-first {
        @include border-radius($radius 0 0 $radius);
        border-right: 0;
        float: left;
    }
    .form-row-last inputinput:not([type=checkbox]):not([type=submit]), input.form-row-last, input.set-last {
        @include border-radius( 0 $radius $radius 0);
        float: left;
    }
}
