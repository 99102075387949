.container, body.sidebar-primary .main_container {
  @include main-container();
}
/**
 **  Fixes for IE6-8
 **  http://jonikorpi.com/leaving-old-IE-behind/
 */

.ie body {
  width: 540px;
  margin: 0 auto;
}

$layout: 12;

@each $col in $cols {
  .col_#{$col} {
    @include span(full);
    //padding-bottom: rhythm();
  }
}
.grid_item {
  @include span($layout);
}
.sidebar, .aside {
   @include span(full);
   padding-bottom: rhythm();
   //padding-bottom: rhythm();
}
.main {
  > nav {
     @include span(full);
  }
  &.push, &.pull {
    @include span(full);
     //padding-bottom: rhythm();
  }
}
body.sidebar-primary .main_container {
  .main {
     @include span(full);
   }
}
.nest {
  @include nest();
}
/* @media screen and (min-width: 720px) */
@include susy-media($break2) {
  @each $col in $cols {
    .col_#{$col} {
      @include span($layout/$col);
      &.gutless {
        padding-left: 0;
        padding-right: 0;
      }
      &.right {
        float: right;
      }
    }
  }
  .grid_item {
    @include span($layout/2);
  }
  .sidebar, .aside {
    @include span(($layout/4)); 
    float: left;
    border-top: none;
    padding-bottom: 0;
  }
  .main {
    > nav {
      @include span(full);
    }
    &.push {
      @include span(($layout/4)*3); 
      float: right;
    }
    &.pull {
      @include span(($layout/4)*3); 
      float: left;
    }
  }
  .banner {
    .main {
      @include span(($layout/3)*2 no-gutters); 
      float: right;
    }
  }
  body.sidebar-primary {
    .main_container {
     @include main-container();
     .main {
        @include span(($layout/3)*2 no-gutters); 
        float: right;
      }
    }
  }
}/* @media screen and (min-width: 720px) */

@include susy-media($break4) {
  .grid_item {
    @include span($layout/3);
  }
}
@include susy-media($break6) {
  .grid_item {
    @include span($layout/4);
  }
}