html:after {
	// used to test if browser can read getComputedStyle on pseudo elements
	content: "js-breakpoints-getComputedStyleTest";
	visibility: hidden;
	position: absolute;
	clip: rect(0 0 0 0);
 	overflow: hidden; 		
 	width: 0;
 	height: 0;
}

@mixin defineBreakpoint($name) {
	// store active breakpoint name in ::pseudo content
	&:after {
		content: $name;
		visibility: hidden;
		position: absolute;		
 		clip: rect(0 0 0 0);
 		overflow: hidden;
	 	width: 0;
	 	height: 0;
	}
	
	// add fallback style using breakpoint name
	.js-breakpoints-#{$name} {
		position: absolute;
	}
}