nav, ul.menu {
  list-style: none;
  padding-left: 0;
  padding-bottom: 0;
  li { 
    //@include inline-elements();
    padding-bottom: 0;
  }
  a, span {
    border-bottom: none;
  }
}

nav {
  ul {
    list-style: none;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.current_page_item a, 
.current_page_parent a, 
.current-menu-item page_item a,
.current a, a.current, span.current {
    cursor: default;
    @include opacity(0.5);
    &:hover {
      color: inherit;
    }
}

.container > nav {
  @extend .col_1;
}

#content > footer > .nav_container {
  @extend .container;
  > nav {
    @extend .col_1;
    padding-bottom: 0;
  }
}


.post-navigation {
  float: left;
  width: 100%;
  text-align: center;
  padding-bottom: rhythm();
  padding-top: rhythm();
  a {
    float: left;
    width: 2em;
    height: 1em;
    position: relative;
    > div {
      position: absolute;
      text-align: left;
      left: 0;
      bottom: 1em;
      white-space: nowrap;
      //display: none;
      @include opacity(0);
      @include transform(translateX(4px));
      @include transition( opacity 200ms $easeOutQuad, transform 200ms $easeOutQuad );
    }
    figure {
      width: 100%;
      display: none;
    }
    &:hover {
      > div {
        height: auto;
        @include opacity(1);
        @include transform(translateX(0));
      }
    }
    &:before {
      float: left;
      text-align: left;
      margin-right: 5px;
    }
  }
  a.prev, .nav-previous a {
    &:before {
      @include bticon($bt-var-long-arrow-left);
      @include adjust-size(1, 1, 0);
    }
  }
  a.next, .nav-next a {
    &:before {
      @include bticon($bt-var-long-arrow-right);
      @include adjust-size(1, 1, 0);
    }
    > div {
      left: -2em;
    }
  }
}



html.js {
	#primary-nav {
		display: none;
	}
	#navigation-toggle {
  	display: block;
	}
}
@include susy-media(0 $break2) {
  #running-header.open {
  	min-height: 100%;
  	width: 100%;
  	background: $clr_bg1;
  	z-index: 650;
  	position: fixed;
  	#primary-nav {
  		display: block;
  		padding-top: rhythm(3);
  		text-align: center;
  		li {
    		width: 100%;
  		}
  	}
  	#secondary-nav {
    	display: none;
  	}
  	#navigation-toggle:after {
      visibility: visible;
      @include bticon($bt-var-times);
    }
  }
}
/* This is the clickable label */
button#navigation-toggle {
	cursor: pointer;
	position: fixed;
	overflow: hidden;
	height: rhythm();
	width: 25px;
	left: pagemargin();
	z-index: 500;
	padding: 0 gutter();
	top: rhythm();
	border: none;
  @include box-shadow(none);
  border: none;
	background: transparent;
	&:hover {
  	background: transparent;
	}
	&:active,
  &:focus {
  	border: none !important;
  	outline: none !important;
  	-webkit-tap-highlight-color: rgba(0,0,0,0);
  }
  &:after {
  	@include bticon($bt-var-bars);
  	text-align: center;
  	padding: 0;
  	margin: 0;
	}
}

/* When it's checked replace the menu icon with a close icon */
@include susy-media($break2) {
  html, html.js {
  	#navigation-toggle {
  		display: none;
  	}
  	#primary-nav {
  		display: block;
  	}
  }
}
@include susy-media($break4) {

}