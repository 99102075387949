#mfPreviewBar { display: none; }

/* 
 lightSlider.js  ------------------------------------------------------------------
*/

.slideshow {
  figure {
    display: none;
  }
  figure:first-child {
    display: block;
  }
  &.lightSlider {
    > figure {
      display: block;
    }
  }
}
html.no-js {
  .slideshow {
    figure {
      display: block;
    }
  }
}
.lSSlideOuter {
  .lslide {
    clear: none;
    margin-bottom: 0;
  }
  .lSPager.lSpg { 
    text-align: left;
    >li.active a, >li:hover a {
      background-color: transparent;
    }
    >li.active a {
      color: $clr_text2;
    }
    li a {
      background-color: transparent;
      border-radius: 0;
      height: auto;
      text-indent: 0;
      width: auto;
      @include transition(inherit);
    }
  }
  .lSAction {
    
  }
  .lSAction > a {
      width: 50%;
      top: 0;
      height: 100%;
      background-image: none;
      cursor: pointer;
      position: absolute;
      z-index: 500;
      margin-top: 0;
      opacity: 1;
      @include transition(inherit);
      &.lSPrev {
        cursor: w-resize;
      }
      &.lSNext {
        cursor: e-resize;
      }
  }
}

/* 
 lightGallery.js  ------------------------------------------------------------------
*/

a[rel=lightbox], .lightbox a {
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
}

.lg-outer {
  .lg-img-wrap {
    padding: 47px;
  }
  .lg-actions {
    .lg-next {
      right: 0;
    }
    .lg-prev {
      left: 0;
    }
  }
}




// bigfoot - v2.1.4 - 2016.02.11


// These are the key variables for styling the popover.
// Just set the variable to none if you don't want that styling.

// KEY VARIABLES
// =============================================================================

// STYLES
$popover-width:                         30em !default;                           // Ideal width of the popover
$popover-max-width:                     90% !default;                            // Best as a % to accommodate smaller viewports
$popover-max-height:                    15em !default;                           // Maximum size of the content area
$popover-color-background:              $clr_text1;  
$popover-color-text:                    $clr_bg1;           // Color of the popover background
$popover-border-radius:                 0;                          // Radius of the corners of the popover
$popover-border:                        none;   // Border of the popover/ tooltip
$popover-inactive-opacity:              0 !default;                              // Opacity of the popover when instantiated/ deactivating
$popover-active-opacity:                1;                           // Opacity of the popover when active
$popover-box-shadow:                    none; // Sets the box shadow under the popover/ tooltip
$popover-bottom-position:               auto !default;                           // Sets the bottom position of the popover. Use only when setting positionPopover to false in the script
$popover-left-position:                 auto !default;                           // Sets the left position of the popover. Use only when setting positionPopover to false in the script
$popover-tooltip-size:                  rhythm(0.5) !default;                          // Sets the side lengths of the tooltip
$popover-scroll-indicator-width:        0 !default;                        // The width of the scroll indicator
$popover-scroll-indicator-aspect-ratio: (15/12) !default;                        // The ratio of the height over the width of the scroll indicator
$popover-scroll-indicator-opacity:      0.1 !default;                            // The active opacity of scroll indicators
$popover-initial-transform-state:       translateY(0) !default;       // The inital transform state for the popover
$popover-active-transform-state:        translateY(-0.25em) !default;         // The transform state for the popover once it is fully activated

// OPTIONAL ELEMENTS
$popover-include-tooltip:               true !default;                           // Adds a tooltip pointing to the footnote button
$popover-include-scroll-indicator:      false !default;                           // Adds an elipsis at the bottom of scrollable popovers
$popover-include-scrolly-fades:         true !default;                           // Fades content in on scrollable popovers
$popover-scroll-indicator-icon:         none;


// OTHER VARIABLES
// =============================================================================

// POPOVER
$popover-margin-top:                             rhythm(0.25);
$popover-padding-content-horizontal:             rhythm(0.5);
$popover-padding-content-top:                    rhythm(0.5);
$popover-padding-content-bottom:                 rhythm(0.5);
$popover-z-index:                                10 !default;                         // Set the base so that it's above the other body children
$popover-initial-transform-origin:               50% 0 !default;

// POPOVER CONTENT WRAPPER
$popover-content-color-background:               $popover-color-background !default;
$popover-content-border-radius:                  $popover-border-radius !default;

// OTHER POPOVER ELEMENTS
$popover-tooltip-background:                     $popover-color-background !default;
$popover-tooltip-radius:                         0 !default;
$popover-scroll-indicator-bottom-position:       0.45em !default;
$popover-scrolly-fade-gradient-start-location:   50% !default;
$popover-scroll-indicator-padding:               (($popover-padding-content-horizontal/2) - ($popover-scroll-indicator-width/2)) !default;

// TRANSITIONS
$popover-transition-default-duration:            0.25s !default;
$popover-scroll-indicator-transition-properties: opacity !default;

// Use none for areas you don't want to transition
$popover-transition-properties:                  opacity, transform !default;         // no mixin to do proper prefixing of the transform, so I have to do it manually; see mixin below
$popover-scroll-indicator-transition-properties: opacity !default;
$popover-scroll-up-transition-delay:             0.4s !default;                       // Sets the delay for the transition of the scroll indicator when scrolling upwards
$popover-transition-default-timing-function:     ease !default;



// These are the key variables for styling the button.
// Just set the variable to none if you don't want that styling.

// KEY VARIABLES
// =============================================================================

$button-height:                         0.95em !default;                         // The total height of the button
$button-width:                          auto !default;                           // The total button width (applies only if $button-apply-dimensions is true)
$button-inner-circle-size:              0.25em !default;                         // Total height/width of the ellipsis circles
$button-border-radius:                  0;                          // Border radius on the button itself
$button-left-margin:                    0.2em !default;                          // Margin between the button and the text to its left
$button-right-margin:                   0.1em !default;                          // Margin between the button and the text to its right
$button-vertical-adjust:                0 !default;                         // Pushes the buttons along the vertical axis to align it with text as desired
$button-inner-circle-left-margin:       1*$button-inner-circle-size !default;    // Space between the ellipsis circles

$button-color:                          transparent;             // Background color of the button
$button-hovered-color:                  inherit;                  // Background color of the button when being hovered
$button-activating-color:               inherit;                  // Background color of the button when being clicked
$button-active-color:                   inherit;                  // Background color of the button when active
$button-standard-opacity:               1;                            // Opacity for when the button is just sittin' there
$button-hovered-opacity:                1;                            // Opacity for when the button is being hovered over
$button-activating-opacity:             1;        // Opacity for when the button is being clicked
$button-active-opacity:                 1;                              // Opacity for when the button is active
$button-active-style-delay:             0.1s !default;                           // Delay before applying .active styles; this can be used to match to the popover activation transition

$button-inner-circle-color:             white !default;                          // Background color of the ellipsis circle
$button-inner-circle-border:            none !default;                           // Border of the ellipsis circle


// OTHER VARIABLES
// =============================================================================

$button-total-padding:              $button-height - $button-inner-circle-size !default;
$button-per-side-padding:           0.5*$button-total-padding !default;
$button-transition-properties:      background-color !default;



// -----



@mixin print-styles {
    // These styles restore the original footnote numbers and texts when the page is printed
    @media not print {
        .footnote-print-only {
            display: none !important;
        }
    }

    @media print {
        .bigfoot-footnote,
        .bigfoot-footnote__button {
            display: none !important;
        }
    }
}



// -----


//*
// The button that activates the footnote. By default, this will appear as a
// flat button that has an ellipse contained inside of it.

// @state .is-active            - The associated popover has been activated and is visible.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__button {
  // POSITIONING
  position: relative;
  z-index: 5;
  top: $button-vertical-adjust;

  // DISPLAY AND SIZING
  box-sizing: border-box;
  -moz-box-sizing: border-box;;
  display: inline-block;
  padding: 0 !important;
  margin: 0 !important;

  // BACKDROP
  border: none;
  border-radius: $button-border-radius;
  cursor: pointer;
  background-color: transparent;
  backface-visibility: hidden;

  // TEXT
  line-height: 0;
  vertical-align: top;
  text-decoration: none;

  &:hover,
  &:focus {
    outline: none;
    background-color: transparent;
  }

  &:active {
    background-color: transparent;
  }

  &.is-active {
    background-color: transparent;
  }

  // Clearfix
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}



// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__container {
  display: inline-block;
  position: relative;
  text-indent: 0;
}



// -----

@include print-styles;


// -----



//*
// The popover for the footnote. This popover will be, by default, be sized and positioned
// by the script. However, many of the sizes can be established in this stylesheet and
// will be respected by the script. `max-width` will limit the width of the popover
// relative to the viewport. `width` (on `bigfoot-footnote__wrapper`) will set the
// absolute max width. Max height can be set via a `max-height` property
// on `bigfoot-footnote__content`.

// By default, the popover has a light gray background, a shadow for some depth,
// rounded corners, and a tooltip pointing to the footnote button.

// @state .is-active            - The popover has been activated and is visible.
// @state .is-positioned-top    - The popover is above the button.
// @state .is-positioned-bottom - The popover is below the button.
// @state .is-scrollable        - The popover content is greater than the popover height.
// @state .is-fully-scrolled    - The popover content is scrolled to the bottom.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote {
  // POSITIONING
  position: absolute;
  z-index: $popover-z-index;
  top: 0; left: 0;

  // DISPLAY AND SIZING
  display: inline-block;
  box-sizing: border-box;
  // Height is set in .footnote-content-wrapper
  max-width: $popover-max-width;
  // 1.414213... is to get the diagonal height of the tooltip using pythagorus, yo.
  margin: ((1.4142135624 * $popover-tooltip-size / 2) + $button-height + $popover-margin-top) 0;
  // fits the popover to the contents

  // BACKDROP
  background: $popover-color-background;
  opacity: $popover-inactive-opacity;
  border-radius: $popover-border-radius;
  border: $popover-border;
  box-shadow: $popover-box-shadow;

  // TEXT
  line-height: 0;

  // TRANSITIONS
  transition-property: $popover-transition-properties;
  transition-duration: $popover-transition-default-duration;
  transition-timing-function: $popover-transition-default-timing-function;

  // TRANSFORMS
  transform: $popover-initial-transform-state;
  transform-origin: $popover-initial-transform-origin;
  
  .bigfoot-footnote__content {
   // @include small();
   color: $popover-color-text;
    >*:last-child {
      padding-bottom: 0;
    }
  }
  
  &.is-positioned-top {
    top: auto;
    bottom: 0;
  }

  &.is-active {
    transform: $popover-active-transform-state;
    opacity: $popover-active-opacity;
  }

  &.is-bottom-fixed {
    // POSITIONING
    position: fixed;
    bottom: 0; top: auto;
    left: 0; right: auto;
    transform: translateY(100%);

    // DISPLAY AND SIZING
    width: 100%;
    margin: 0;

    // BACKDROP
    border-radius: 0;
    opacity: 1;
    border-width: 1px 0 0;

    // TRANSITIONS
    transition: transform 0.3s ease;

    &.is-active {
      transform: translateY(0);
    }

    .bigfoot-footnote__wrapper {
      margin: 0 0 0 50%;
      transform: translateX(-50%);
      max-width: 100%;
    }

    .bigfoot-footnote__wrapper,
    .bigfoot-footnote__content {
      border-radius: 0;
    }
    .bigfoot-footnote__tooltip {
      display: none;
    }
  }

  &.is-scrollable {
    // A scrollable indicator in the left margin of the popover.
    &:after {
      // CONTENT
      content: '';

      // POSITIONING
      position: absolute;
      bottom: $popover-scroll-indicator-padding;
      left: $popover-scroll-indicator-padding;
      z-index: ($popover-z-index + 4);

      // DISPLAY AND SIZING
      display: block;
      height: ($popover-scroll-indicator-width*$popover-scroll-indicator-aspect-ratio);
      width: $popover-scroll-indicator-width;

      // BACKDROP
      background-image: $popover-scroll-indicator-icon;
      background-size: cover;
      opacity: $popover-scroll-indicator-opacity;
      transition-properties: $popover-scroll-indicator-transition-properties;
      transition-duration: $popover-transition-default-duration;
      transition-timing-function: $popover-transition-default-timing-function;
    }

    .bigfoot-footnote__wrapper {
      &:before,
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        // Above the content
        z-index: ($popover-z-index + 2);
        left: 0;
      }

      &:before {
        top: -1px;
        height: $popover-padding-content-top;
        border-radius: $popover-border-radius $popover-border-radius 0 0;
        background-image: linear-gradient(to bottom, $popover-color-background $popover-scrolly-fade-gradient-start-location, transparentize($popover-color-background, 1) 100%);
      }

      &:after {
        bottom: -1px;
        height: $popover-padding-content-bottom;
        border-radius: 0 0 $popover-border-radius $popover-border-radius;
        background-image: linear-gradient(to top, $popover-color-background $popover-scrolly-fade-gradient-start-location, transparentize($popover-color-background, 1) 100%);
      }
    }

    ::-webkit-scrollbar { display: none; }
  }

  &.is-fully-scrolled {
    &:after,
    &:before {
      opacity: 0;
      transition-delay: 0;
    }
  }
}



//*
// Wraps around the footnote content. This is necessary in order to have an element
// above the tooltip and that can provide top and bottom indicators that there is
// additional content on scrollable popovers.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__wrapper {
  // POSITIONING
  position: relative;
  // Above the outer tooltip, below the inner tooltip
  z-index: ($popover-z-index + 4);

  // DISPLAY AND SIZING
  width: $popover-width;
  display: inline-block;
  box-sizing: inherit;
  overflow: hidden;
  margin: 0;

  // BACKDROP
  background-color: $popover-color-background;
  border-radius: $popover-border-radius;

  // TEXT
  line-height: 0;
}



//*
// Contains the actual footnote content. There is very little prescription here
// on the footnote content itself, except for removing and top margin on the first
// element and bottom margin on the last child.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__content {
  // POSITIONING
  position: relative;
  z-index: ($popover-z-index - 2); // Below fading bars

  // DISPLAY AND SIZING
  display: inline-block;
  max-height: $popover-max-height;
  padding: $popover-padding-content-top $popover-padding-content-horizontal $popover-padding-content-bottom;
  box-sizing: inherit;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  // BACKDROP
  background: $popover-content-color-background;

  line-height: normal;

  // INTERIOR ELEMENTS
  img { max-width: 100%; }
  *:last-child { margin-bottom: 0 !important; }
  *:first-child { margin-top: 0 !important; }
}



//*
// A triangular shape pointing towards the footnote button.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__tooltip {
  // POSITIONING
  position: absolute;
  // Above the footnote-main-wrapper and the outer tooltip
  z-index: ($popover-z-index + 2);

  // DISPLAY AND SIZING
  box-sizing: border-box;
  margin-left: (-0.5 * $popover-tooltip-size);
  // Smaller by one border-width's worth
  width: $popover-tooltip-size;
  height: $popover-tooltip-size;
  transform: rotate(45deg);

  // BACKDROP
  background: $popover-tooltip-background;
  border: $popover-border;
  box-shadow: $popover-box-shadow;
  border-top-left-radius: $popover-tooltip-radius;

  .is-positioned-bottom & {
    top: (-0.5 * $popover-tooltip-size);
  }

  .is-positioned-top & {
    bottom: (-0.5 * $popover-tooltip-size);
  }
}



// -----


//*
// A button that has no ellipse, but instead shows the footnote's number on the
// page. Note that the number will be reset to 1 depending on the selector passed
// to bigfoot's `numberResetSelector` option.

// @since 2.1.0
// @author Chris Sauve

.bigfoot-footnote__button {
  position: relative;
  height: $button-height;
  width: 0.6em;

  &:after {
    // CONTENT
    content: attr(data-footnote-number);

    // POSITION
    position: absolute;
    top: 50%; left: 50%;
    transform: translate(-50%, -50%);

    // DISPLAY AND SIZING
    display: block;

    // TEXT
    font-size: $button-height*0.6;
  }

  &:hover,
  &.is-active {
    &:after {
    }
  }
}

.bigfoot-footnote__button__circle {
  display: none;
}
