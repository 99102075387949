ol.comment-list, ol.commentlist  {
  list-style: none;
  display: block;
  float: left;
  clear: both;
  margin-bottom: rhythm();
  border-top: $border2;
}
.comment {
  float: left;
  width: 100%;
  padding-bottom: rhythm(0.5);
  padding-top: rhythm(0.5);
  border-bottom: $border2;
  @include nested(8) {
    figure { 
      @include span(1);
      padding-left: 0;
      padding-bottom: 0;
      margin-bottom: 0;
    }
    .media-body { 
      @include span(7);
      padding-right: 0;
    }
    .media-heading, .meta {
      display: inline;
    }
  }
}

.comment-reply-link {
  float: left;
  margin-top: #{$baseline*-1}em;
}

.comment-form p {
}
.comment-form input[type="text"],
.comment-form input[type="email"],
.comment-form input[type="url"],
.comment-form textarea {
}
.comment-form input[type="submit"] {
}