html, body {
  height: 100%;
  min-height: 100%;
}

#running-header {
  position: relative; 
  backface-visibility: hidden;
  overflow: visible;
  padding-top: rhythm();
  .col_2, .col_3 {
    position: relative;
  }
  .header-3 {
    float: right;
  }
  > .container {
    padding-top: rhythm(0.75);
    padding-bottom: rhythm(0.75);
  }
}

#site-header {
  background: $clr_bg1;
  z-index: 100;
  overflow: hidden;
  padding: rhythm(0.25) 0;
  p:last-child {
    padding-bottom: 0;
  }
  > div {
    margin: 0 0 0 0;
    @include transition(margin $easeInOutQuad 200ms);
    &:hover {
      span {
        display: inline;
      }
    }
    &.stuck {
      margin: 0.75em 0 0.75em 0;
      span {
        display: none;
      }
      > p:last-child {
        padding-bottom: 0;
      }
      &:hover {
        margin: 0 0 0 0;
        span {
          display: inline;
        }
      }
    }
  }
}

#document {
  position: relative;
  display: block;
  @include transition(opacity $easeOutQuad 500ms);
  overflow: visible;
  height: auto;
}

#content {
  position: relative;
  z-index: 20;
}

#running-footer {
  position: relative;
  width: 100%;
  z-index: 5;
  .container {
    padding-top: rhythm(3);
    > div {
      font-size: 0.8em;
    }
  }
}

.container {
  padding-top: rhythm();
  padding-bottom: rhythm();
}

body.sidebar-primary {
  .main_container {
    padding-bottom: rhythm();
  }
}
.sidebar {
  h3 {
    padding-bottom: 0;
  }
}

.main_container {
  padding-top: rhythm(3);
}
#content {
  > header {
    padding-bottom: rhythm();
  }
  > article {
    .container {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

html {
  &.initiated {
    #content {
      @include transition(opacity $easeOutQuad 500ms);
      @include opacity(1);
    }
  }
}


#splash {
  position: absolute;
  width: 100%;
  min-height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  top: 0;
  left: 0;
  z-index: 999;
  display: none;
  opacity: 0;
  padding-bottom: rhythm(1);
  display: none;
  .container {
    text-align: center;
  }
}

body.splash-initiated {
  #splash {
    display: flex;
    opacity: 1;
  }
  #document {
    position: fixed;
    opacity: 0;
    height: 100%;
    overflow: hidden;
    @include transition(opacity $easeOutQuad 500ms);
  }
}

@include susy-media($break2) {
  #site-header {
    > div {
      margin: 0 0 0 0;
      @include transition(margin $easeInOutQuad 200ms);
      span {
        @include opacity(1);
        @include transition(opacity $easeInOutQuad 200ms);
      }
      &:hover {
        span {
          @include opacity(1);
        }
      }
      &.stuck {
        margin: 0.75em 0 -0.75em 0;
        span {
          @include opacity(0);
          display: inline;
        }
        > p:last-child {
          padding-bottom: 0;
        }
        &:hover {
          margin: 0 0 0 0;
          span {
            @include opacity(1);
          }
        }
      }
    }
  }
}
@include susy-media($break4) {

}
@include susy-media($break6) {

}
