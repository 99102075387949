

#page-intro {
  padding-bottom: rhythm(2);
}

#page-header {
  //text-align: center;
  padding-bottom: rhythm(2);
}

nav.index {
  padding-top: rhythm(2);
  a > span {
    font-family: $font-a;
    font-style: italic;
    font-size: 1em;
  }
}