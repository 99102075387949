/* 
  Media queries  ------------------------------------------------------------------
*/
@include susy-media($break2) {
    //@include establish-baseline($base-font-size + 1);
    //font-size: $base-font-size + 1;
    
    body {
        @include defineBreakpoint("BREAK2");
        @include adjust-size(1);
        //@include adjust-size(1.01, 1, 0, 0);
    }
}
@include susy-media($break4) {
   //@include establish-baseline($base-font-size + 2);
   body {
        //@include adjust-size(1.01, 1, 0, 0);
    }
}
@include susy-media($break6) {
    //@include establish-baseline($base-font-size + 3);
   body {
        //@include adjust-size(1.01, 1, 0, 0);
    }
}
