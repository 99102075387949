$susy: (
  columns: 12,
  column-width: 100px, // it really doesn't matter what number this is...
  gutters: 20px/100px, // as long as you get this ratio right...
  gutter-position: inside-static,
  //debug: (image: show)
);

/* Vertical Rhythm */

$base-font-size: 18px; // Sets the base font size
$base-line-height: 24px; // Sets the base line height 
$round-to-nearest-half-line: true; // Allows compass to round to  multiples of 0.5x line height 
$rhythm-unit: "rem"; // Sets rhythm unit to rem. Remove to use default em unit. 

$baseline: $base-line-height / $base-font-size;
$leading: #{$baseline}em;

/* Grid */

$break1: 40em;
$break2: 45em;
$break3: 61.5em;
$break4: 87em;
$break5: 117em;
$break6: 130em;

$cols: 1, 2, 3, 4, 6, 12;

/* Colors */

$clr_text1: #131670;
$clr_text2: $clr_text1;
$clr_link: $clr_text1;
$clr_hover: #e36708;
$clr_bg1: #f8f7f1;
$clr_bg2: $clr_text1;
$clr_bg3: #c9edc3;
$clr_border: lighten($clr_text1, 15);
$clr_border2: darken($clr_bg1, 10);

$clr_error: #d80b00;
$clr_success: #00b671;
$clr_warning: #d8af00;

/* Fonts */

@import "black-tie/_variables";
@import "black-tie/_mixins";
@import "black-tie/_icons";

@font-face {
  font-family: 'Black Tie';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/BlackTie-Regular-webfont.eot');src: url('../fonts/BlackTie-Regular-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/BlackTie-Regular-webfont.woff') format('woff'),url('../fonts/BlackTie-Regular-webfont.ttf') format('truetype');
}

@font-face {
  font-family: 'Garamond Classic';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/GaramondClassic-Regular-webfont.eot');src: url('../fonts/GaramondClassic-Regular-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/GaramondClassic-Regular-webfont.woff2') format('woff2'),url('../fonts/GaramondClassic-Regular-webfont.woff') format('woff'),url('../fonts/GaramondClassic-Regular-webfontt.ttf') format('truetype');
}
@font-face {
  font-family: 'Garamond Classic';
  font-style: italic;
  font-weight: normal;
  src: url('../fonts/GaramondClassic-Italic-webfont.eot');src: url('../fonts/GaramondClassic-Italic-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/GaramondClassic-Italic-webfont.woff2') format('woff2'),url('../fonts/GaramondClassic-Italic-webfont.woff') format('woff'),url('../fonts/GaramondClassic-Italic-webfontt.ttf') format('truetype');
}
@font-face {
  font-family: 'Garamond Classic';
  font-style: normal;
  font-weight: bold;
  src: url('../fonts/GaramondClassic-Bold-webfont.eot');src: url('../fonts/GaramondClassic-Bold-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/GaramondClassic-Bold-webfont.woff2') format('woff2'),url('../fonts/GaramondClassic-Bold-webfont.woff') format('woff'),url('../fonts/GaramondClassic-Bold-webfontt.ttf') format('truetype');
}
@font-face {
  font-family: 'Garamond Classic';
  font-style: italic;
  font-weight: bold;
  src: url('../fonts/GaramondClassic-BoldItalic-webfont.eot');src: url('../fonts/GaramondClassic-BoldItalic-webfont.eot?#iefix') format('embedded-opentype'),url('../fonts/GaramondClassic-BoldItalic-webfont.woff2') format('woff2'),url('../fonts/GaramondClassic-BoldItalic-webfont.woff') format('woff'),url('../fonts/GaramondClassic-BoldItalic-webfontt.ttf') format('truetype');
}


$font-a: 'Garamond Classic', "Georgia", "Times New Roman", times, serif;
$font-b: "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-c: $font-a;


$font-icon: "Black Tie";



@mixin font-b() {
  font-family: $font-b;
  font-size: 0.85em;
  font-weight: 400;
}

/* Etc */

$radius: 0;

$border: 1px solid $clr_border;
$border2: 1px solid $clr_border2;
$border-hvy: 8px solid $clr_border;

/* Functions */

@function pagemargin($n:1) {
  @return rhythm($n);
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {
    @return $number / ($number * 0 + 1);
  }

  @return $number;
}

/* Mixins —————————————————————————————————————————————————————————————————————————————————————  */ 


/* Structure & Layout */

@mixin main-container() {
  @include container;
  position: relative;
  padding: 0 pagemargin(1);
  max-width: 1200px;
  margin: 0 auto;
}

@mixin modal() {
  max-width: 600px;
  margin: rhythm() auto;
  padding: rhythm();
  @include border-radius($radius);
  
  float: none;
  display: inline-block;
  background: $clr_bg3;
}

@mixin squeeze($span, $total: $layout, $full:0) {
  $margin: ($total - $span)*$full;
  @include susy-media($break2) {
    @include span($span of $total);
  }
  @include susy-media($break3) {
    @include span($span - 1 of $total);
    @include push(($margin + 1)/2);
    @include post(($margin + 1)/2);
  }
  @include susy-media($break4) {
    @include span($span - 2 of $total);
    @include push(($margin + 2)/2);
    @include post(($margin + 2)/2);
  }
}

@mixin pad-element($v: 1, $h:1) {
	padding: rhythm(0.25)*$v 0.75em*$h;
	@include inline-block;
}

@mixin inline-elements() {
  float: left;
  margin-right: 1em;
}

@mixin nest() {
  float: left;
  clear: both;
  margin-left: -1*gutter();
  margin-right: -1*gutter();
}


/* Backgrounds */


@mixin bg3() {
  background: $clr_bg3;
}

@mixin bg2() {
  background: $clr_bg2;
  color: $clr_bg1;
  a, button, .button {
    color: $clr_bg1;
    border-color: $clr_bg1;
    &:hover {
      background-color: $clr_bg1;
    }
  }
}


/* Typography */


@mixin adjust-size($new-size, $lines: 1, $below:1, $above:0) {
  $ratio: $new-size*$base-font-size;
  font-size: #{$new-size}em;
  line-height: strip-unit($baseline / $new-size * $lines);
  padding-bottom: rhythm($below, $ratio);
  padding-top: rhythm($above, $ratio);
}

@mixin heading() {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: 400;
}

@mixin spaced() {
  letter-spacing: 2px;
  text-transform: uppercase;
}

@mixin small() {
  font-size: 0.8em;
}

@mixin h1($padBottom: 0, $padTop:0) {
  @include adjust-size(1.75, 1.5, $padBottom, $padTop);
  font-family: $font-c;
  &:first-child {
    padding-top: 0;
  }
}

@mixin h1_alt($padBottom: 0, $padTop:0) {
  @include adjust-size(3, 2, $padBottom, $padTop);
}

@mixin h2($padBottom: 0, $padTop:0) {
  @include adjust-size(1.25, 1, $padBottom, $padTop);
  //@include spaced();
  &:first-child {
    padding-top: 0;
  }
}

@mixin h2_alt($padBottom: 0, $padTop:0) {
  @include adjust-size(2, 1.5, $padBottom/2, $padTop/2);
  text-transform: none;
  letter-spacing: 0px;
  @include susy-media($break2) {
    @include adjust-size(2.5, 1.75, $padBottom/1.5, $padTop/1.5);
  }
  @include susy-media($break3) {
    @include adjust-size(3, 2, $padBottom, $padTop);
  }
}

@mixin h3($padBottom: 0, $padTop:0) {
  @include adjust-size(1, 1, $padBottom, $padTop);
  font-style: italic;
  &:first-child {
    padding-top: 0;
  }
}

@mixin underline($clr: $clr_border2) {
  border-bottom: 1px solid $clr;
  padding-bottom: #{$baseline/12}em;
}

@mixin subtext() {
  font-family: "Sabon Italic";
  //color: $clr_text2;
  a {
    //color: $clr_text2;
    &:hover {
      //color: $clr_hover;
    }
  }
}


@mixin label() {
  @include adjust-size(1, 1, 0.5, 0.5);
  color: $clr_text2;
}

@mixin placeholder {
  &.placeholder { @content }
  &:-moz-placeholder { @content }
  &::-webkit-input-placeholder { @content }
}

@mixin bticon($content: "", $line-height: 1, $rel-size: 1) {
  @include bt-icon();
	content: $content;
	font-size: 14px;
  line-height: inherit;
  vertical-align: baseline;
}

@mixin fabicon($content: "", $line-height: 1, $rel-size: 1) {
  @include bt-icon();
  font-family: "Font Awesome Brands";
	content: $content;
	font-size: 14px;
  line-height: ($base-line-height/$base-font-size)*($base-font-size/14px)*$line-height;
}

@mixin icon($content: "") {
    display: inline-block;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  	content: $content; 
}